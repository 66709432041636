import React from "react";

import { SeoComponent } from "../components/SeoComponent";
import TeamCard from "../components/TeamCard";
import { useTeams } from "../hooks/useTeams";

const Teams = () => {
  const teams = useTeams();
  const sorted = teams.sort((a, b) => a.fullName.localeCompare(b.fullName));
  return (
    <div className="flex flex-col">
      <h1 className="text-pink-500 font-bold text-center lg:mt-0 mt-4">
        Süper Lig Takımları
      </h1>
      <div className="flex-wrap flex justify-center dark:text-white dark:bg-gray-900">
        {sorted.map((team) => {
          return <TeamCard key={team.fullName} {...team} />;
        })}
      </div>
    </div>
  );
};

export default Teams;

export const Head = () => (
  <SeoComponent
    title="Süper Lig Takımları"
    description="Türkiye Futbol Süper Ligi 2024-25 Sezonu Takımları: Galatasaray, Fenerbahçe, Beşiktaş, Trabzonspor ve fazlası"
    keywords="Futbol, Takımlar, Adana Demirspor, Alanyaspor, Antalyaspor, Başakşehir, Basaksehir, Beşiktaş, Besiktas, Bodrumspor, Eyüpspor,
    Eyupspor, Galatasaray, Fenerbahçe, Gaziantep, Gaziantep FK, Goztepe, Göztepe Hatayspor, Kasımpaşa, Kasimpasa, Konyaspor,
    Rizespor, Sivasspor, Trabzonspor, Süperlig, Superlig, ensuperlig, ensüperlig"
  />
);
