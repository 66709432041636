import React from "react";
import { Link } from "gatsby";
import { FaFacebook, FaTwitter, FaYoutube, FaInstagram, FaHome } from "react-icons/fa";

import ExternalLink from "../components/ExternalLink";
import { getTeamURL } from "../util/urlUtil";
import { logos } from "../util/logos";

const TeamCard = ({
  fullName,
  slug,
  facebook,
  twitter,
  youtube,
  instagram,
  website
}) => {
  return (
    <div
      className={`border border-${slug}-1 border-solid border-2 m-4 shadow-2xl`}
    >
      <Link
        className="flex flex-col h-72 w-72 relative w-full"
        to={getTeamURL(fullName)}
      >
        <div className={`bg-${slug}-3 h-36`}>
        </div>
        <div className={`pt-16 grow bg-${slug}-1`}>
          <div
            className={`text-${slug}-2 flex justify-center mb-2 font-semibold text-lg`}
          >
            {fullName}
          </div>
          <div className="flex justify-center">
          {website && (
              <ExternalLink
                textClass={`text-${slug}-2`}
                Icon={FaHome}
                path={website}
              />
            )}
            {facebook && (
              <ExternalLink
                textClass={`text-${slug}-2`}
                Icon={FaFacebook}
                path={facebook}
              />
            )}
            {twitter && (
              <ExternalLink
                textClass={`text-${slug}-2`}
                Icon={FaTwitter}
                path={twitter}
              />
            )}
            {youtube && (
              <ExternalLink
                textClass={`text-${slug}-2`}
                Icon={FaYoutube}
                path={youtube}
              />
            )}
            {instagram && (
              <ExternalLink
                textClass={`text-${slug}-2`}
                Icon={FaInstagram}
                path={instagram}
              />
            )}
          </div>
        </div>
        <div className={`absolute flex justify-center top-24 left-24 rounded-full z-10 p-3 bg-white border border-solid border-${slug}-1`}>
          <img width="70px" height="70px" src={logos[slug]} alt={fullName} />
        </div>
      </Link>
    </div>
  );
};

export default TeamCard;
